import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-07ec4a18")
const _hoisted_1 = {
  key: 1,
  class: "chart"
}
const _hoisted_2 = { class: "flex flex-col justify-between px-4 mb-6 sm:px-0 xs:flex-row xs:flex-wrap dark:border-gray-900" }
const _hoisted_3 = { class: "flex mb-4" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "flex flex-col items-start text-2xl font-bold xs:items-end tabular-nums" }
const _hoisted_6 = { class: "tracking-tighter" }
const _hoisted_7 = { class: "tracking-tighter" }
_popScopeId()

import { format } from 'date-fns';
import * as echarts from 'echarts/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { PRETTY_DATE_FORMAT } from '@/components/forms/lock_actions/constants';
import PoolChartPeriodSelect from '@/components/pool/PoolChartPeriodSelect.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useDarkMode from '@/composables/useDarkMode';
import useNumbers from '@/composables/useNumbers';
import useTailwind from '@/composables/useTailwind';
import { HistoricalPrices } from '@/services/coingecko/api/price.service';
import {
  Pool,
  PoolSnapshot,
  PoolSnapshots,
  PoolType
} from '@/services/balancer/subgraph/types';

/**
 * TYPES
 */
export type PoolChartPeriod = {
  text: string;
  days: number;
};

type Props = {
  historicalPrices: HistoricalPrices;
  snapshots: PoolSnapshots;
  loading: boolean;
  pool: Pool;
  // these props are added to prevent line chart rerender on each pool update
  totalLiquidity?: string;
  tokensList?: string[];
  poolType?: PoolType;
};

enum PoolChartTab {
  VOLUME = 'volume',
  TVL = 'tvl',
  FEES = 'fees'
}

interface PoolChartData {
  color: string[];
  hoverBorderColor?: string;
  hoverColor?: string;
  areaStyle?: {
    color: echarts.LinearGradientObject;
  };
  chartType: string;
  data: {
    name: string;
    values: (readonly (string | number)[])[];
  }[];
  defaultHeaderStateValue: string;
}

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    historicalPrices: { type: null, required: true },
    snapshots: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false },
    pool: { type: null, required: true },
    totalLiquidity: { type: String, required: false },
    tokensList: { type: Array, required: false },
    poolType: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  historicalPrices: HistoricalPrices;
  snapshots: PoolSnapshots;
  loading: boolean;
  pool: Pool;
  // these props are added to prevent line chart rerender on each pool update
  totalLiquidity?: string;
  tokensList?: string[];
  poolType?: PoolType;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const store = useStore();
const { t } = useI18n();
const tailwind = useTailwind();
const { fNum2 } = useNumbers();
const { isMobile } = useBreakpoints();
const { darkMode } = useDarkMode();

/**
 * STATE
 */
const MIN_CHART_VALUES = 2;

const tabs = [
  {
    value: PoolChartTab.VOLUME,
    label: t('poolChart.tabs.volume')
  },
  {
    value: PoolChartTab.TVL,
    label: t('poolChart.tabs.tvl')
  },
  {
    value: PoolChartTab.FEES,
    label: t('poolChart.tabs.fees')
  }
];
const activeTab = ref(tabs[0].value);

const currentChartValue = ref('');
const currentChartDate = ref('');
const isFocusedOnChart = ref(false);

/**
 * COMPUTED
 */
const appLoading = computed(() => store.state.app.loading);

const snapshotValues = computed(() => Object.values(props.snapshots || []));

const periodOptions = computed(() => [
  { text: t('poolChart.period.days', [90]), days: 90 },
  { text: t('poolChart.period.days', [180]), days: 180 },
  { text: t('poolChart.period.days', [365]), days: 365 },
  { text: t('poolChart.period.all'), days: snapshotValues.value.length }
]);

const currentPeriod = ref<PoolChartPeriod>(periodOptions.value[0]);

const timestamps = computed(() =>
  snapshotValues.value.map(snapshot => format(snapshot.timestamp, 'yyyy/MM/dd'))
);

function getTVLData(periodSnapshots: PoolSnapshot[]) {
  const tvlValues: (readonly (string | number)[])[] = [];

  // temporary statement until we start get prices from coingecko for
  if (props.poolType === PoolType.StablePhantom) {
    periodSnapshots.forEach((snapshot, idx) => {
      const timestamp = timestamps.value[idx];
      if (idx === 0) {
        tvlValues.push(
          Object.freeze([timestamp, Number(props.totalLiquidity || 0)])
        );
        return;
      }
      tvlValues.push(Object.freeze([timestamp, Number(snapshot.liquidity)]));
    });
  } else {
    periodSnapshots.forEach((snapshot, idx) => {
      const timestamp = timestamps.value[idx];
      // get today's TVL value from pool.totalLiquidity due to differences in prices during the day
      if (idx === 0) {
        tvlValues.push(
          Object.freeze([timestamp, Number(props.totalLiquidity || 0)])
        );
        return;
      }

      const prices = props.historicalPrices[snapshot.timestamp];

      // timestamp is removed if there are no prices from coingecko
      if (!prices || prices.length < (props.tokensList?.length || 0)) {
        return;
      }

      let amounts = [...snapshot.amounts];

      /**
       * @description
       * There may be more amounts in snapshots than prices.
       * For example in balancer boosted pool the largest one is the BPT of the pool itself.
       * It is removed here to calculate properly snapshot pool value.
       */
      if (snapshot.amounts.length > prices.length) {
        const maxValue = Math.max(
          ...snapshot.amounts.map(amount => Number(amount))
        );

        amounts = amounts.filter(
          amount => Number(amount).toFixed() !== maxValue?.toString()
        );
      }

      const snapshotPoolValue = amounts.reduce(
        (sum: number, amount: string, index: number) => {
          sum += Number(amount) * prices[index];
          return sum;
        },
        0
      );

      tvlValues.push(Object.freeze([timestamp, snapshotPoolValue]));
    });
  }

  return {
    color: [tailwind.theme.colors.blue['600']],
    hoverBorderColor: tailwind.theme.colors.pink['500'],
    hoverColor: darkMode.value
      ? tailwind.theme.colors.gray['900']
      : tailwind.theme.colors.white,
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(14, 165, 233, 0.08)'
        },
        {
          offset: 1,
          color: 'rgba(68, 9, 236, 0)'
        }
      ])
    },
    chartType: 'line',
    data: [
      {
        name: 'TVL',
        values: tvlValues
      }
    ],
    defaultHeaderStateValue: fNum2(tvlValues[0][1], {
      style: 'currency'
    })
  };
}

function getFeesData(
  periodSnapshots: PoolSnapshot[],
  isAllTimeSelected: boolean,
  pariodLastSnapshotIdx: number
) {
  const feesValues = periodSnapshots.map((snapshot, idx) => {
    const value = parseFloat(snapshot.swapFees);
    let prevValue: number;

    // get value of prev snapshot
    // if it is last value among all snapshots, then prev value is 0
    if (idx === snapshotValues.value.length - 1) {
      prevValue = 0;
    } // if it is last value among certain period snapshots, then we get prev value from all snapshots
    else if (idx === pariodLastSnapshotIdx) {
      prevValue = parseFloat(snapshotValues.value[idx + 1].swapFees);
    } else {
      prevValue = parseFloat(periodSnapshots[idx + 1].swapFees);
    }
    return Object.freeze([timestamps.value[idx], value - prevValue]);
  });

  const defaultHeaderStateValue =
    Number(periodSnapshots[0].swapFees) -
    (isAllTimeSelected
      ? 0
      : Number(periodSnapshots[pariodLastSnapshotIdx].swapFees));

  return {
    color: [tailwind.theme.colors.yellow['400']],
    chartType: 'bar',
    hoverColor: tailwind.theme.colors.pink['500'],
    data: [
      {
        name: 'Fees',
        values: feesValues
      }
    ],
    defaultHeaderStateValue: fNum2(defaultHeaderStateValue, {
      style: 'currency'
    })
  };
}

function getVolumeData(
  periodSnapshots: PoolSnapshot[],
  isAllTimeSelected: boolean,
  pariodLastSnapshotIdx: number
): PoolChartData {
  const volumeData = periodSnapshots.map((snapshot, idx) => {
    const value = parseFloat(snapshot.swapVolume);
    let prevValue: number;

    // get value of prev snapshot
    if (idx === snapshotValues.value.length - 1) {
      prevValue = 0;
    } else if (idx === pariodLastSnapshotIdx) {
      prevValue = parseFloat(snapshotValues.value[idx + 1].swapVolume);
    } else {
      prevValue = parseFloat(periodSnapshots[idx + 1].swapVolume);
    }
    return Object.freeze([timestamps.value[idx], value - prevValue]);
  });

  const defaultHeaderStateValue =
    Number(periodSnapshots[0].swapVolume) -
    (isAllTimeSelected
      ? 0
      : Number(periodSnapshots[pariodLastSnapshotIdx].swapVolume));

  return {
    color: [tailwind.theme.colors.green['400']],
    chartType: 'bar',
    hoverColor: tailwind.theme.colors.pink['500'],
    data: [
      {
        name: 'Volume',
        values: volumeData
      }
    ],
    defaultHeaderStateValue: fNum2(defaultHeaderStateValue, {
      style: 'currency'
    })
  };
}

const chartData = computed(
  (): PoolChartData => {
    const periodSnapshots =
      currentPeriod.value.days === snapshotValues.value.length
        ? snapshotValues.value
        : snapshotValues.value.slice(0, currentPeriod.value.days - 1);
    const isAllTimeSelected =
      periodSnapshots.length === snapshotValues.value.length;
    const pariodLastSnapshotIdx = periodSnapshots.length - 1;

    if (activeTab.value === PoolChartTab.TVL) {
      return getTVLData(periodSnapshots);
    }

    if (activeTab.value === PoolChartTab.FEES) {
      return getFeesData(
        periodSnapshots,
        isAllTimeSelected,
        pariodLastSnapshotIdx
      );
    }

    return getVolumeData(
      periodSnapshots,
      isAllTimeSelected,
      pariodLastSnapshotIdx
    );
  }
);

const defaultChartData = computed(() => {
  const currentPeriodOption = periodOptions.value.find(
    option => option.days === currentPeriod.value.days
  );
  let title = `${currentPeriodOption?.text} ${activeTab.value}`;

  if (activeTab.value === PoolChartTab.TVL) {
    title = t('poolChart.defaultTitle.tvl');
  }

  return { title, value: chartData.value.defaultHeaderStateValue };
});

/**
 * METHODS
 */
function setCurrentPeriod(period: PoolChartPeriod) {
  currentPeriod.value = period;
}

function setCurrentChartValue(payload: {
  chartDate: string;
  chartValue: number;
}) {
  currentChartValue.value = fNum2(payload.chartValue, {
    style: 'currency'
  });
  currentChartDate.value = format(
    new Date(payload.chartDate),
    PRETTY_DATE_FORMAT
  );
}

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalTabs = _resolveComponent("BalTabs")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBlankSlate = _resolveComponent("BalBlankSlate")!
  const _component_BalChart = _resolveComponent("BalChart")!

  return (__props.loading || _unref(appLoading))
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-96"
      }))
    : (_unref(snapshotValues).length >= MIN_CHART_VALUES)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BalTabs, {
                modelValue: activeTab.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = $event)),
                tabs: tabs,
                "no-pad": "",
                class: "mr-6 -mb-px"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(PoolChartPeriodSelect, {
                  options: _unref(periodOptions),
                  "active-option": currentPeriod.value,
                  onChangeOption: setCurrentPeriod
                }, null, 8, ["options", "active-option"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(isFocusedOnChart.value ? currentChartValue.value : _unref(defaultChartData).value), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["text-sm font-medium text-gray-500", { 'text-pink-500': isFocusedOnChart.value }])
              }, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(isFocusedOnChart.value ? currentChartDate.value : _unref(defaultChartData).title), 1)
              ], 2)
            ])
          ]),
          (_unref(chartData).data[0].values.length <= MIN_CHART_VALUES)
            ? (_openBlock(), _createBlock(_component_BalBlankSlate, {
                key: 0,
                class: "h-96"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, { name: "bar-chart" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('noPriceInfo')), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_BalChart, {
                key: 1,
                height: "96",
                data: _unref(chartData).data,
                "axis-label-formatter": {
        yAxis: { style: 'currency', abbreviate: true, maximumFractionDigits: 0 }
      },
                "area-style": _unref(chartData).areaStyle,
                color: _unref(chartData).color,
                "hover-color": _unref(chartData).hoverColor,
                "hover-border-color": _unref(chartData).hoverBorderColor,
                "x-axis-min-interval": 3600 * 1000 * 24 * 30,
                "show-legend": false,
                "need-chart-value": "",
                "chart-type": _unref(chartData).chartType,
                "show-tooltip-layer": false,
                onSetCurrentChartValue: setCurrentChartValue,
                "hide-y-axis": _unref(isMobile),
                onMouseLeaveEvent: _cache[1] || (_cache[1] = ($event: any) => (isFocusedOnChart.value = false)),
                onMouseEnterEvent: _cache[2] || (_cache[2] = ($event: any) => (isFocusedOnChart.value = true))
              }, null, 8, ["data", "area-style", "color", "hover-color", "hover-border-color", "chart-type", "hide-y-axis"]))
        ]))
      : (_openBlock(), _createBlock(_component_BalBlankSlate, {
          key: 2,
          class: "h-96"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, { name: "bar-chart" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('insufficientData')), 1)
          ]),
          _: 1
        }))
}
}

})