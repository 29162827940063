import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "px-6 py-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex center mr-3" }
const _hoisted_4 = { class: "px-6 py-4 flex -mt-1 flex-wrap" }
const _hoisted_5 = {
  key: 0,
  class: "m-1 flex items-center p-1 px-2 bg-gray-50 dark:bg-gray-700 rounded-lg"
}
const _hoisted_6 = { class: "font-numeric" }
const _hoisted_7 = { class: "px-6 py-4 flex justify-end font-numeric" }
const _hoisted_8 = { class: "px-6 py-4" }
const _hoisted_9 = { class: "flex items-center justify-end wrap whitespace-nowrap text-right" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDistanceToNow } from 'date-fns';
import { getAddress } from '@ethersproject/address';

import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useBreakpoints from '@/composables/useBreakpoints';

import {
  PoolActivity,
  PoolActivityType
} from '@/services/balancer/subgraph/types';
import useWeb3 from '@/services/web3/useWeb3';

import { bnum } from '@/lib/utils';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';

/**
 * TYPES
 */

type TokenAmount = {
  address: string;
  symbol: string;
  amount: string;
};

type ActivityRow = {
  label: string;
  formattedValue: string;
  value: number;
  timestamp: number;
  formattedDate: string;
  tx: string;
  type: PoolActivityType;
  tokenAmounts: TokenAmount[];
};

type Props = {
  tokens: string[];
  poolActivities: PoolActivity[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokens: { type: Array, required: true },
    poolActivities: { type: Array, required: true },
    isLoading: { type: Boolean, required: false, default: false },
    isLoadingMore: { type: Boolean, required: false, default: false },
    loadMore: { type: Function, required: false },
    isPaginated: { type: Boolean, required: false, default: false },
    noResultsLabel: { type: String, required: false }
  } as unknown as undefined,
  emits: ['loadMore'],
  setup(__props: {
  tokens: string[];
  poolActivities: PoolActivity[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
}, { emit }) {

const props = __props




/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { t } = useI18n();
const { explorerLinks } = useWeb3();
const { tokens, priceFor } = useTokens();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * COMPUTED
 */
const columns = computed<ColumnDefinition<ActivityRow>[]>(() => [
  {
    name: t('action'),
    id: 'action',
    accessor: 'tx',
    Cell: 'actionCell',
    width: 150,
    sortable: false
  },
  {
    name: t('details'),
    id: 'details',
    accessor: '',
    Cell: 'detailsCell',
    width: 325,
    sortable: false
  },
  {
    name: t('value'),
    id: 'value',
    accessor: 'value',
    Cell: 'valueCell',
    align: 'right',
    className: 'align-center w-40',
    sortKey: pool => pool.value,
    width: 125
  },
  {
    name: t('time'),
    id: 'timeAgo',
    accessor: 'timestamp',
    Cell: 'timeCell',
    align: 'right',
    sortKey: pool => pool.timestamp,
    width: 200
  }
]);

const activityRows = computed<ActivityRow[]>(() =>
  props.isLoading
    ? []
    : props.poolActivities.map(({ type, timestamp, tx, amounts }) => {
        const isJoin = type === 'Join';
        const value = getJoinExitValue(amounts);

        return {
          label: isJoin ? t('invest') : t('withdraw.label'),
          value,
          formattedValue: value > 0 ? fNum(fNum(value, 'usd'), 'usd_m') : '-',
          timestamp,
          formattedDate: t('timeAgo', [formatDistanceToNow(timestamp)]),
          tx,
          type,
          tokenAmounts: getJoinExitDetails(amounts)
        };
      })
);

/**
 * METHODS
 */
function getJoinExitValue(amounts: PoolActivity['amounts']) {
  let total = bnum(0);

  for (let i = 0; i < amounts.length; i++) {
    const amount = amounts[i];
    const address = getAddress(props.tokens[i]);
    const token = tokens.value[address];
    const price = priceFor(token.address);
    const amountNumber = Math.abs(parseFloat(amount));

    // If the price is unknown for any of the positive amounts - the value cannot be computed.
    if (amountNumber > 0 && price === 0) {
      return 0;
    }

    total = total.plus(bnum(amountNumber).times(price));
  }

  return total.toNumber();
}

function getJoinExitDetails(amounts: PoolActivity['amounts']) {
  return amounts.map((amount, index) => {
    const address = getAddress(props.tokens[index]);
    const token = tokens.value[address];
    const symbol = token ? token.symbol : address;
    const amountNumber = parseFloat(amount);

    return {
      address,
      symbol,
      amount: fNum(amountNumber, 'token')
    };
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    class: "overflow-x-auto",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: _unref(columns),
        data: _unref(activityRows),
        "is-loading": __props.isLoading,
        "is-loading-more": __props.isLoadingMore,
        "is-paginated": __props.isPaginated,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (emit('loadMore'))),
        "skeleton-class": "h-64",
        sticky: "both",
        "no-results-label": __props.noResultsLabel,
        "initial-state": {
        sortColumn: 'timeAgo',
        sortDirection: 'desc'
      }
      }, {
        actionCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (action.type === 'Join')
                  ? (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 0,
                      name: "plus",
                      size: "sm",
                      class: "text-green-500 dark:text-green-400"
                    }))
                  : (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 1,
                      name: "minus",
                      size: "sm",
                      class: "text-red-500"
                    }))
              ]),
              _createElementVNode("div", null, _toDisplayString(action.label), 1)
            ])
          ])
        ]),
        detailsCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.tokenAmounts, (tokenAmount, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (tokenAmount.amount !== '0')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_BalAsset, {
                        address: tokenAmount.address,
                        class: "mr-2 flex-shrink-0"
                      }, null, 8, ["address"]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(tokenAmount.amount), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]),
        valueCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_7, _toDisplayString(action.formattedValue), 1)
        ]),
        timeCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(action.formattedDate) + " ", 1),
              _createVNode(_component_BalLink, {
                href: _unref(explorerLinks).txLink(action.tx),
                external: "",
                class: "ml-2 flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "text-gray-500 hover:text-blue-500 transition-colors"
                  })
                ]),
                _: 2
              }, 1032, ["href"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "is-paginated", "no-results-label"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})