import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = {
  key: 1,
  class: "pl-2 hidden lg:inline-block"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_AppNavSettings = _resolveComponent("AppNavSettings")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createBlock(_component_BalPopover, { "no-pad": "" }, {
    activator: _withCtx(() => [
      _createVNode(_component_BalBtn, {
        class: _normalizeClass(["text-base", { btn: _ctx.upToLargeBreakpoint }]),
        loading: _ctx.isLoadingProfile,
        "loading-label": _ctx.upToLargeBreakpoint ? '' : _ctx.$t('connecting'),
        color: "white",
        size: _ctx.upToLargeBreakpoint ? 'md' : 'sm',
        circle: _ctx.upToLargeBreakpoint
      }, {
        default: _withCtx(() => [
          (_ctx.profile && _ctx.profile.ens)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                textContent: _toDisplayString(_ctx.profile && _ctx.profile.ens),
                class: "pl-2 hidden lg:inline-block"
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, [
                (_ctx.name)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      textContent: _toDisplayString(_ctx.name)
                    }, null, 8, _hoisted_3))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      textContent: _toDisplayString(_ctx._shorten(_ctx.account))
                    }, null, 8, _hoisted_4))
              ]))
        ]),
        _: 1
      }, 8, ["class", "loading", "loading-label", "size", "circle"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_AppNavSettings)
    ]),
    _: 1
  }))
}