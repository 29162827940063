
import { defineComponent, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { EXTERNAL_LINKS } from '@/constants/links';
import TokenSearchInput from '@/components/inputs/TokenSearchInput.vue';
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import FeaturedPools from '@/components/sections/FeaturedPools.vue';
import usePools from '@/composables/pools/usePools';
import useWeb3 from '@/services/web3/useWeb3';
import usePoolFilters from '@/composables/pools/usePoolFilters';
import useAlerts, { AlertPriority, AlertType } from '@/composables/useAlerts';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers from '@/composables/useNumbers';

export default defineComponent({
  components: {
    TokenSearchInput,
    PoolsTable,
    FeaturedPools
  },

  setup() {
    // COMPOSABLES
    const { fNum } = useNumbers();
    const router = useRouter();
    const { t } = useI18n();
    const { isWalletReady, isV1Supported, appNetworkConfig } = useWeb3();
    const isElementSupported = appNetworkConfig.supportsElementPools;
    const {
      selectedTokens,
      addSelectedToken,
      removeSelectedToken
    } = usePoolFilters();

    const {
      pools,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      loadMorePools,
      poolsHasNextPage,
      poolsIsFetchingNextPage,
      poolsQuery
    } = usePools(selectedTokens);
    const { addAlert, removeAlert } = useAlerts();
    const { upToMediumBreakpoint } = useBreakpoints();
    const smumIndexPool =
      '0xa287a3722c367849efa5c76e96be36efd65c290e000100000000000000000020';

    // COMPUTED
    const filteredPools = computed(() =>
      selectedTokens.value.length > 0
        ? pools.value?.filter(pool => {
            return selectedTokens.value.every((selectedToken: string) =>
              pool.tokenAddresses.includes(selectedToken)
            );
          })
        : pools?.value?.filter(pool => pool.id !== smumIndexPool)
    );

    const indexPools = computed(() => {
      return selectedTokens.value.length > 0
        ? pools.value?.filter(pool => {
            if (pool.id !== smumIndexPool) return false;
            return selectedTokens.value.every((selectedToken: string) =>
              pool.tokenAddresses.includes(selectedToken)
            );
          })
        : pools?.value?.filter(pool => pool.id === smumIndexPool);
    });

    const getTotalLiquidity = computed(() => {
      const filtered =
        selectedTokens.value.length > 0
          ? pools.value?.filter(pool => {
              return selectedTokens.value.every((selectedToken: string) =>
                pool.tokenAddresses.includes(selectedToken)
              );
            })
          : pools?.value;

      const tvl = filtered.reduce(
        (previous, current) => (previous += +current.totalLiquidity),
        0
      );

      return tvl.toFixed(2);
    });

    const hideV1Links = computed(() => !isV1Supported);

    watch(poolsQuery.error, () => {
      if (poolsQuery.error.value) {
        addAlert({
          id: 'pools-fetch-error',
          label: t('alerts.pools-fetch-error'),
          type: AlertType.ERROR,
          persistent: true,
          action: poolsQuery.refetch.value,
          actionLabel: t('alerts.retry-label'),
          priority: AlertPriority.MEDIUM
        });
      } else {
        removeAlert('pools-fetch-error');
      }
    });

    /**
     * METHODS
     */
    function navigateToCreatePool() {
      router.push({ name: 'create-pool' });
    }

    return {
      // data
      filteredPools,
      indexPools,
      userPools,
      isLoadingPools,
      isLoadingUserPools,
      getTotalLiquidity,

      // computed
      isWalletReady,
      hideV1Links,
      poolsHasNextPage,
      poolsIsFetchingNextPage,
      selectedTokens,
      isElementSupported,
      upToMediumBreakpoint,

      //methods
      router,
      loadMorePools,
      addSelectedToken,
      removeSelectedToken,
      navigateToCreatePool,
      fNum,

      // constants
      EXTERNAL_LINKS
    };
  }
});
