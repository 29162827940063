
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import BalIcon from '../BalIcon/BalIcon.vue';

type Placement = 'top' | 'left' | 'bottom' | 'right';

export default defineComponent({
  name: 'Tooltip',
  components: { BalIcon },
  props: {
    text: { type: String, default: '' },
    placement: { type: String as PropType<Placement>, default: 'top' },
    onShow: { type: Function },
    onHide: { type: Function },
    noPad: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    iconSize: { type: String, defailt: 'md' },
    width: { type: String, default: '52' },
    textCenter: { type: Boolean, default: false }
  },
  setup(props) {
    const activator = ref<HTMLElement>();
    const content = ref<HTMLElement>();
    const popper = ref<PopperInstance>();

    const tooltipClasses = computed(() => {
      return {
        'p-3': !props.noPad,
        [`w-${props.width}`]: true,
        'text-center': props.textCenter
      };
    });

    // show the tooltip
    const handleMouseEnter = () => {
      if (!props.disabled && content.value && popper.value) {
        content.value.setAttribute('data-show', '');
        popper.value.update();
        props.onShow && props.onShow();
      }
    };

    // hide the tooltip
    const handleMouseLeave = () => {
      if (!props.disabled && content.value) {
        content.value.removeAttribute('data-show');
        props.onHide && props.onHide();
      }
    };

    onMounted(() => {
      if (activator.value && content.value) {
        popper.value = createPopper(activator.value, content.value, {
          placement: props.placement,
          modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
        });
      }
    });

    return {
      activator,
      content,
      handleMouseEnter,
      handleMouseLeave,
      tooltipClasses
    };
  }
});
