import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-sm text-gray-500" }
const _hoisted_2 = { class: "flex items-center justify-between mb-4" }
const _hoisted_3 = {
  key: 1,
  class: "grid grid-cols-2 gap-2"
}

import { toRef, computed } from 'vue';
import useWithdrawMath from '@/components/forms/pool_actions/WithdrawForm/composables/useWithdrawMath';
import { FullPool } from '@/services/balancer/subgraph/types';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';
import { lpTokensFor } from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { hasBpt } = useWithdrawMath(toRef(props, 'pool'));
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { isWalletReady, toggleWalletSelectModal } = useWeb3();

/**
 * COMPUTED
 */
const fiatTotal = computed(() => {
  const fiatValue = lpTokensFor(props.pool)
    .map(address => {
      let tokenBalance = '0';

      if (
        wrappedNativeAsset.value != undefined &&
        address === wrappedNativeAsset.value.address
      ) {
        const wrappedBalance = balanceFor(address);
        const nativeBalance = balanceFor(nativeAsset.address);
        tokenBalance = bnum(nativeBalance).gt(wrappedBalance)
          ? nativeBalance
          : wrappedBalance;
      } else {
        tokenBalance = balanceFor(address);
      }

      return toFiat(tokenBalance, address);
    })
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );

  return fNum(fiatValue, currency.value);
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('basedOnTokensInWallet')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('youCanInvest')), 1),
        _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fiatTotal) : '-'), 1)
      ]),
      (!_unref(isWalletReady))
        ? (_openBlock(), _createBlock(_component_BalBtn, {
            key: 0,
            label: _ctx.$t('connectWallet'),
            color: "symmetric",
            block: "",
            onClick: _unref(toggleWalletSelectModal)
          }, null, 8, ["label", "onClick"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_BalBtn, {
              tag: "router-link",
              to: { name: 'invest' },
              label: _ctx.$t('invest'),
              color: "symmetric",
              block: ""
            }, null, 8, ["label"]),
            _createVNode(_component_BalBtn, {
              tag: _unref(hasBpt) ? 'router-link' : 'div',
              to: { name: 'withdraw' },
              label: _ctx.$t('withdraw.label'),
              disabled: !_unref(hasBpt),
              block: ""
            }, null, 8, ["tag", "label", "disabled"])
          ]))
    ]),
    _: 1
  }))
}
}

})