import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  width: "30",
  class: "mr-2"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_0,
  width: "30",
  class: "mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.darkMode)
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}