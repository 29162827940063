import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_4 = { class: "flex flex-col text-right" }
const _hoisted_5 = { class: "text-sm text-gray-500" }

import { computed } from 'vue';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';

/**
 * TYPES
 */
type Props = {
  address: string;
  balance: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    address: { type: String, required: true },
    balance: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  address: string;
  balance: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getToken } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();

/**
 * COMPUTED
 */
const token = computed(() => getToken(props.address));

const balanceLabel = computed(() => fNum(props.balance, 'token'));

const fiatLabel = computed(() => {
  const fiatValue = toFiat(props.balance, props.address);
  return fNum(fiatValue, currency.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_unref(token).symbol), 1),
      (_unref(token).name !== _unref(token).symbol)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(token).name), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_unref(balanceLabel)), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(fiatLabel)), 1)
    ])
  ]))
}
}

})