import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-be380b00")
const _hoisted_1 = { class: "data-table" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "data-table-number-col" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { key: 1 }
_popScopeId()

import { computed, toRefs } from 'vue';
import { WithdrawMathResponse } from '../composables/useWithdrawMath';
import useNumbers from '@/composables/useNumbers';

/**
 * TYPES
 */
type Props = {
  math: WithdrawMathResponse;
};

/**
 * Props
 */

export default _defineComponent({
  props: {
    math: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  math: WithdrawMathResponse;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();

const { priceImpact, highPriceImpact, loadingAmountsOut } = toRefs(props.math);

/**
 * COMPUTED
 */
const priceImpactClasses = computed(() => ({
  'bg-red-500 text-white divide-red-400 border-none': highPriceImpact.value
}));

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['data-table-row', _unref(priceImpactClasses)])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('priceImpact')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_unref(loadingAmountsOut))
            ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                key: 0,
                class: "w-10 h-6"
              }))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(fNum)(_unref(priceImpact), 'percent')), 1)),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('withdraw.tooltips.priceImpact')
          }, {
            activator: _withCtx(() => [
              (_unref(highPriceImpact))
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "alert-triangle",
                    size: "xs",
                    class: "-mb-px ml-1"
                  }))
                : (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 1,
                    name: "info",
                    size: "xs",
                    class: "text-gray-400 -mb-px ml-1"
                  }))
            ]),
            _: 1
          }, 8, ["text"])
        ])
      ])
    ], 2)
  ]))
}
}

})