import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full border-b dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-4" }
const _hoisted_3 = { class: "pt-4 flex justify-between font-medium" }
const _hoisted_4 = { class: "text-right" }

import { computed, toRef, ref } from 'vue';
import { FullPool } from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';
import PoolCalculator from '@/services/pool/calculator/calculator.sevice';
// Composables
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
// Components
import AssetRow from './components/AssetRow.vue';
import { usePool } from '@/composables/usePool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  hideHeader?: boolean;
};
/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    hideHeader: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  hideHeader?: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { tokens, balances, balanceFor } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { isStablePhantomPool } = usePool(toRef(props, 'pool'));

/**
 * SERVICES
 */
const poolCalculator = new PoolCalculator(
  toRef(props, 'pool'),
  tokens,
  balances,
  'exit',
  ref(false)
);

/**
 * COMPUTED
 */
const bptBalance = computed((): string => balanceFor(props.pool.address));

const propTokenAmounts = computed((): string[] => {
  const { receive } = poolCalculator.propAmountsGiven(
    bptBalance.value,
    0,
    'send'
  );

  if (isStablePhantomPool.value) {
    // Return linear pool's main token balance using the price rate.
    // mainTokenBalance = linearPoolBPT * priceRate
    return props.pool.tokenAddresses.map((address, i) => {
      if (!props.pool.onchain.linearPools) return '0';

      const priceRate = props.pool.onchain.linearPools[address].priceRate;

      return bnum(receive[i])
        .times(priceRate)
        .toString();
    });
  }

  return receive;
});

const tokenAddresses = computed((): string[] => {
  if (isStablePhantomPool.value) {
    // We're using mainToken balances for StablePhantom pools
    // so return mainTokens here so that fiat values are correct.
    return props.pool.mainTokens || [];
  }
  return props.pool.tokenAddresses;
});

const fiatTotal = computed(() => {
  const fiatValue = tokenAddresses.value
    .map((address, i) => toFiat(propTokenAmounts.value[i], address))
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );
  return fNum(fiatValue, currency.value);
});

return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "none",
    noPad: ""
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses), (address, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: address,
            class: "py-2"
          }, [
            _createVNode(AssetRow, {
              address: address,
              balance: _unref(propTokenAmounts)[i]
            }, null, 8, ["address", "balance"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('total')), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(fiatTotal)), 1)
        ])
      ])
    ]),
    _: 2
  }, [
    (!__props.hideHeader)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('poolTransfer.myPoolBalancesCard.title')), 1)
            ])
          ])
        }
      : undefined
  ]), 1024))
}
}

})