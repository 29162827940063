import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between text-gray-400 dark:text-gray-600 mt-4 text-sm" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2" }

import { toRef, toRefs, computed, reactive, watch } from 'vue';
import PoolExchange from '@/services/pool/exchange/exchange.service';
import { poolWeightsLabel } from '@/composables/usePool';
// Types
import { FullPool } from '@/services/balancer/subgraph/types';
import {
  TransactionReceipt,
  TransactionResponse
} from '@ethersproject/abstract-provider';
import { InvestMathResponse } from '../../../composables/useInvestMath';
// Composables
import useWeb3 from '@/services/web3/useWeb3';
import useTransactions from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import { useI18n } from 'vue-i18n';
import { dateTimeLabelFor } from '@/composables/useTime';
import { useRoute } from 'vue-router';
import useConfig from '@/composables/useConfig';
import { configService } from '@/services/config/config.service';
import { BigNumber } from 'ethers';
import { formatUnits } from '@ethersproject/units';
import useTokenApprovalActions from '@/composables/useTokenApprovalActions';
import { TransactionActionInfo } from '@/types/transactions';
import BalActionSteps from '@/components/_global/BalActionSteps/BalActionSteps.vue';
import { boostedJoinBatchSwap } from '@/lib/utils/balancer/swapper';
/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  math: InvestMathResponse;
  tokenAddresses: string[];
};

type InvestmentState = {
  init: boolean;
  confirming: boolean;
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    math: { type: null, required: true },
    tokenAddresses: { type: Array, required: true }
  } as unknown as undefined,
  emits: ["success"] as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  math: InvestMathResponse;
  tokenAddresses: string[];
}, { emit }: { emit: ({
  (e: 'success', value: TransactionReceipt): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const investmentState = reactive<InvestmentState>({
  init: false,
  confirming: false,
  confirmed: false,
  confirmedAt: ''
});

/**
 * COMPOSABLES
 */
const route = useRoute();
const { t } = useI18n();
const { networkConfig } = useConfig();
const { account, getProvider, explorerLinks, blockNumber } = useWeb3();
const { addTransaction } = useTransactions();
const { txListener, getTxConfirmedAt } = useEthers();
const {
  fullAmounts,
  batchSwapAmountMap,
  bptOut,
  fiatTotalLabel,
  batchSwap,
  shouldFetchBatchSwap
} = toRefs(props.math);

const { tokenApprovalActions } = useTokenApprovalActions(
  props.tokenAddresses,
  fullAmounts
);

/**
 * SERVICES
 */
const poolExchange = new PoolExchange(toRef(props, 'pool'));

/**
 * COMPUTED
 */
const actions = computed((): TransactionActionInfo[] => [
  ...tokenApprovalActions,
  {
    label: t('invest'),
    loadingLabel: t('investment.preview.loadingLabel.investment'),
    confirmingLabel: t('confirming'),
    action: submit,
    stepTooltip: t('investmentTooltip')
  }
]);

const explorerLink = computed((): string =>
  investmentState.receipt
    ? explorerLinks.txLink(investmentState.receipt.transactionHash)
    : ''
);

const transactionInProgress = computed(
  (): boolean =>
    investmentState.init ||
    investmentState.confirming ||
    investmentState.confirmed
);

/**
 * METHODS
 */

async function handleTransaction(tx): Promise<void> {
  addTransaction({
    id: tx.hash,
    type: 'tx',
    action: 'invest',
    summary: t('transactionSummary.investInPool', [
      fiatTotalLabel.value,
      poolWeightsLabel(props.pool)
    ]),
    details: {
      total: fiatTotalLabel.value,
      pool: props.pool
    }
  });

  await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      emit('success', receipt);
      investmentState.receipt = receipt;

      const confirmedAt = await getTxConfirmedAt(receipt);
      investmentState.confirmedAt = dateTimeLabelFor(confirmedAt);
      investmentState.confirmed = true;
      investmentState.confirming = false;
    },
    onTxFailed: () => {
      console.error('Invest failed');
      investmentState.confirming = false;
    }
  });
}

async function submit(): Promise<TransactionResponse> {
  try {
    let tx;
    investmentState.init = true;

    if (batchSwap.value) {
      tx = await boostedJoinBatchSwap(
        configService.network.key,
        getProvider(),
        batchSwap.value.swaps,
        batchSwap.value.assets,
        props.pool.address,
        batchSwapAmountMap.value,
        BigNumber.from(bptOut.value)
      );
    } else {
      tx = await poolExchange.join(
        getProvider(),
        account.value,
        fullAmounts.value,
        props.tokenAddresses,
        formatUnits(bptOut.value, props.pool.onchain.decimals)
      );
    }

    investmentState.init = false;
    investmentState.confirming = true;

    console.log('Receipt', tx);

    handleTransaction(tx);
    return tx;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * WATCHERS
 */
watch(blockNumber, async () => {
  if (shouldFetchBatchSwap.value && !transactionInProgress.value) {
    await props.math.getBatchSwap();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BalActionSteps, { actions: _unref(actions) }, null, 8, ["actions"]),
    (_unref(investmentState).confirmed)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BalIcon, { name: "clock" }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(investmentState).confirmedAt), 1)
            ]),
            _createVNode(_component_BalLink, {
              href: _unref(explorerLink),
              external: "",
              noStyle: "",
              class: "group flex items-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm",
                  class: "ml-px group-hover:text-pink-500 transition-colors"
                })
              ]),
              _: 1
            }, 8, ["href"])
          ]),
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'pool', params: { id: _unref(route).params.id } },
            color: "gray",
            outline: "",
            block: "",
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('returnToPool')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})