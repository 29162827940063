import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

import { uniqueId } from 'lodash';
import { computed, useSlots } from 'vue';

type Spacing = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | 'none';
type Props = {
  vertical?: boolean;
  horizontal?: boolean;
  spacing?: Spacing;
  withBorder?: boolean;
  ref?: any;
  align?: 'center' | 'start' | 'end' | 'between';
  justify?: 'center' | 'start' | 'end' | 'between';
  isDynamic?: boolean;
  expandChildren?: boolean;
};


export default _defineComponent({
  props: {
    vertical: { type: Boolean, required: false },
    horizontal: { type: Boolean, required: false },
    spacing: { type: String, required: false, default: 'base' },
    withBorder: { type: Boolean, required: false },
    ref: { type: null, required: false },
    align: { type: String, required: false },
    justify: { type: String, required: false },
    isDynamic: { type: Boolean, required: false },
    expandChildren: { type: Boolean, required: false }
  } as unknown as undefined,
  setup(__props: {
  vertical?: boolean;
  horizontal?: boolean;
  spacing?: Spacing;
  withBorder?: boolean;
  ref?: any;
  align?: 'center' | 'start' | 'end' | 'between';
  justify?: 'center' | 'start' | 'end' | 'between';
  isDynamic?: boolean;
  expandChildren?: boolean;
}) {

const props = __props
const SpacingMap: Record<Spacing, number> = {
  xs: 1,
  sm: 2,
  base: 4,
  lg: 8,
  xl: 12,
  '2xl': 16,
  none: 0
};



const spacingClass = computed(() => {
  const spacingType = props.vertical ? 'mb' : 'mr';
  return `${spacingType}-${SpacingMap[props.spacing]}`;
});

const stackId = uniqueId();
const slots = useSlots();

const slotsWithContent = computed(() => {
  if (props.isDynamic) {
    if (Array.isArray(slots.default()[0].children)) {
      return (slots.default()[0].children as any[]).filter(
        child => child.children !== 'v-if'
      );
    }
  }
  return slots.default().filter(slot => {
    if (slot.children !== 'v-if') return true;
    return false;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex',
      {
        'flex-row': __props.horizontal,
        'flex-col': __props.vertical,
        'items-center': __props.align === 'center',
        'items-start': __props.align === 'start',
        'items-end': __props.align === 'end',
        'items-between': __props.align === 'between',
        'justify-center': __props.justify === 'center',
        'justify-start': __props.justify === 'start',
        'justify-end': __props.justify === 'end',
        'justify-between': __props.justify === 'between'
      }
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(slotsWithContent), (child, i) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(child), {
        key: `stack-${_unref(stackId)}-child-${i}-${child?.key || ''}`,
        class: _normalizeClass({
        [_unref(spacingClass)]: i !== _unref(slotsWithContent).length - 1,
        'border-b': i !== _unref(slotsWithContent).length - 1 && __props.withBorder && __props.vertical,
        'border-r':
          i !== _unref(slotsWithContent).length - 1 && __props.withBorder && __props.horizontal,
        'w-full': __props.expandChildren,
        'dark:border-gray-600': true
      })
      }, null, 8, ["class"]))
    }), 128))
  ], 2))
}
}

})