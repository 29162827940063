import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ef30d2b4")
const _hoisted_1 = { class: "h-10 text-base period-select-input" }
const _hoisted_2 = { class: "flex items-center justify-between flex-1 h-full" }
const _hoisted_3 = { class: "period-select-input__selected" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "px-3 py-2 text-sm font-medium text-gray-500 border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-900 whitespace-nowrap" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex items-center ml-1 font-medium" }
_popScopeId()

import { PoolChartPeriod } from '@/components/contextual/pages/pool/PoolChart.vue';

/**
 * TYPES
 */
type Props = {
  options: PoolChartPeriod[];
  activeOption: PoolChartPeriod;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    options: { type: Array, required: true },
    activeOption: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  options: PoolChartPeriod[];
  activeOption: PoolChartPeriod;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalPopover = _resolveComponent("BalPopover")!

  return (_openBlock(), _createBlock(_component_BalPopover, { "no-pad": "" }, {
    activator: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(__props.activeOption.text), 1),
          _createVNode(_component_BalIcon, { name: "chevron-down" })
        ])
      ])
    ]),
    default: _withCtx(({ close }) => [
      _createElementVNode("div", {
        onClick: close,
        class: "flex flex-col overflow-hidden rounded-lg w-44"
      }, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('poolChart.period.title')) + ": ", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            key: option.days,
            onClick: ($event: any) => (_ctx.$emit('change-option', option)),
            class: "flex items-center justify-between p-3 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-850"
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(option.text), 1),
            (__props.activeOption.days === option.days)
              ? (_openBlock(), _createBlock(_component_BalIcon, {
                  key: 0,
                  name: "check",
                  class: "text-blue-500"
                }))
              : _createCommentVNode("", true)
          ], 8, _hoisted_6))
        }), 128))
      ], 8, _hoisted_4)
    ]),
    _: 1
  }))
}
}

})