import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col pt-1"
}
const _hoisted_2 = { class: "flex items-center justify-between text-sm text-gray-500 leading-none" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mx-2"
}
const _hoisted_5 = {
  key: 0,
  class: "text-blue-500"
}
const _hoisted_6 = {
  key: 1,
  class: "text-gray-400 dark:text-gray-600 lowercase"
}
const _hoisted_7 = {
  key: 1,
  class: "mt-2 text-yellow-600 dark:text-yellow-400 text-xs"
}

import { HtmlInputEvent } from '@/types';
import { ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import useUserSettings from '@/composables/useUserSettings';

import { bnum } from '@/lib/utils';
import { isPositive, isLessThanOrEqualTo } from '@/lib/utils/validations';

import useWeb3 from '@/services/web3/useWeb3';

import { Rules } from '@/components/_global/BalTextInput/BalTextInput.vue';
import TokenSelectInput from '@/components/inputs/TokenSelectInput/TokenSelectInput.vue';

import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type InputValue = string | number;

type Props = {
  amount: InputValue;
  address?: string;
  weight?: number | string;
  noRules?: boolean;
  noMax?: boolean;
  priceImpact?: number;
  label?: string;
  fixedToken?: boolean;
  customBalance?: string;
  balanceLabel?: string;
  disableMax?: boolean;
  balanceLoading?: boolean;
  hint?: string;
  hintAmount?: string;
  excludedTokens?: string[];
  options?: string[];
  rules?: Rules;
  disableNativeAssetBuffer?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    amount: { type: [String, Number], required: true, default: '' },
    address: { type: String, required: false, default: '' },
    weight: { type: [Number, String], required: false, default: 0 },
    noRules: { type: Boolean, required: false, default: false },
    noMax: { type: Boolean, required: false, default: false },
    priceImpact: { type: Number, required: false },
    label: { type: String, required: false },
    fixedToken: { type: Boolean, required: false, default: false },
    customBalance: { type: String, required: false },
    balanceLabel: { type: String, required: false },
    disableMax: { type: Boolean, required: false, default: false },
    balanceLoading: { type: Boolean, required: false, default: false },
    hint: { type: String, required: false },
    hintAmount: { type: String, required: false, default: '' },
    excludedTokens: { type: Array, required: false },
    options: { type: Array, required: false, default: () => [] },
    rules: { type: null, required: false, default: () => [] },
    disableNativeAssetBuffer: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["blur", "input", "update:amount", "update:address", "update:isValid", "keydown"] as unknown as undefined,
  setup(__props: {
  amount: InputValue;
  address?: string;
  weight?: number | string;
  noRules?: boolean;
  noMax?: boolean;
  priceImpact?: number;
  label?: string;
  fixedToken?: boolean;
  customBalance?: string;
  balanceLabel?: string;
  disableMax?: boolean;
  balanceLoading?: boolean;
  hint?: string;
  hintAmount?: string;
  excludedTokens?: string[];
  options?: string[];
  rules?: Rules;
  disableNativeAssetBuffer?: boolean;
}, { emit }: { emit: ({
  (e: 'blur', value: string): void;
  (e: 'input', value: string): void;
  (e: 'update:amount', value: string): void;
  (e: 'update:address', value: string): void;
  (e: 'update:isValid', value: boolean): void;
  (e: 'keydown', value: HtmlInputEvent);
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const _amount = ref<InputValue>('');
const _address = ref<string>('');

/**
 * COMPOSABLEs
 */

const { getToken, balanceFor, nativeAsset } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { t } = useI18n();
const { isWalletReady } = useWeb3();

/**
 * COMPUTED
 */
const hasToken = computed(() => !!_address.value);
const amountBN = computed(() => bnum(_amount.value));
const tokenBalanceBN = computed(() => bnum(tokenBalance.value));
const hasAmount = computed(() => amountBN.value.gt(0));
const hasBalance = computed(() => tokenBalanceBN.value.gt(0));
const shouldUseTxBuffer = computed(
  () =>
    _address.value === nativeAsset.address && !props.disableNativeAssetBuffer
);
const amountExceedsTokenBalance = computed(() =>
  amountBN.value.gt(tokenBalance.value)
);
const shouldShowTxBufferMessage = computed(() => {
  if (
    amountExceedsTokenBalance.value ||
    !shouldUseTxBuffer.value ||
    !hasBalance.value ||
    !hasAmount.value
  ) {
    return false;
  }

  return amountBN.value.gte(
    tokenBalanceBN.value.minus(nativeAsset.minTransactionBuffer)
  );
});

const isMaxed = computed(() => {
  if (shouldUseTxBuffer.value) {
    return (
      _amount.value ===
      tokenBalanceBN.value.minus(nativeAsset.minTransactionBuffer).toString()
    );
  } else {
    return _amount.value === tokenBalance.value;
  }
});

const tokenBalance = computed(() => {
  if (props.customBalance) return props.customBalance;
  return balanceFor(_address.value);
});

const token = computed((): TokenInfo | undefined => {
  if (!hasToken.value) return undefined;
  return getToken(_address.value);
});

const tokenValue = computed(() => {
  return toFiat(_amount.value, _address.value);
});

const inputRules = computed(() => {
  if (!hasToken.value || !isWalletReady.value || props.noRules)
    return [isPositive()];
  return [
    ...props.rules,
    isPositive(),
    isLessThanOrEqualTo(tokenBalance.value, t('exceedsBalance'))
  ];
});

const maxPercentage = computed(() => {
  if (!hasBalance.value || !hasAmount.value) return '0';

  return amountBN.value
    .div(tokenBalance.value)
    .times(100)
    .toFixed(2);
});

const bufferPercentage = computed(() => {
  if (!shouldShowTxBufferMessage.value) return '0';

  return bnum(nativeAsset.minTransactionBuffer)
    .div(tokenBalance.value)
    .times(100)
    .toFixed(2);
});

const barColor = computed(() =>
  amountExceedsTokenBalance.value ? 'red' : 'green'
);

const priceImpactSign = computed(() =>
  (props.priceImpact || 0) >= 0 ? '-' : '+'
);

const priceImpactClass = computed(() =>
  (props.priceImpact || 0) >= 0.01 ? 'text-red-500' : ''
);

/**
 * METHODS
 */
const setMax = () => {
  if (props.disableMax) return;

  if (
    _address.value === nativeAsset.address &&
    !props.disableNativeAssetBuffer
  ) {
    // Subtract buffer for gas
    _amount.value = tokenBalanceBN.value.gt(nativeAsset.minTransactionBuffer)
      ? tokenBalanceBN.value.minus(nativeAsset.minTransactionBuffer).toString()
      : '0';
  } else {
    _amount.value = tokenBalance.value;
  }

  emit('update:amount', _amount.value);
};

/**
 * CALLBACKS
 */
watchEffect(() => {
  _amount.value = props.amount;
  _address.value = props.address;
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalProgressBar = _resolveComponent("BalProgressBar")!
  const _component_BalTextInput = _resolveComponent("BalTextInput")!

  return (_openBlock(), _createBlock(_component_BalTextInput, _mergeProps({
    modelValue: _amount.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_amount.value = $event)),
    placeholder: __props.hintAmount || '0.0',
    type: "number",
    label: __props.label,
    decimalLimit: _unref(token)?.decimals || 18,
    rules: _unref(inputRules),
    validateOn: "input",
    autocomplete: "off",
    autocorrect: "off",
    step: "any",
    spellcheck: "false"
  }, _ctx.$attrs, {
    inputAlignRight: "",
    onBlur: _cache[4] || (_cache[4] = ($event: any) => (emit('blur', $event))),
    onInput: _cache[5] || (_cache[5] = ($event: any) => (emit('input', $event))),
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (emit('update:amount', $event))),
    "onUpdate:isValid": _cache[7] || (_cache[7] = ($event: any) => (emit('update:isValid', $event))),
    onKeydown: _cache[8] || (_cache[8] = ($event: any) => (emit('keydown', $event)))
  }), {
    prepend: _withCtx(() => [
      _renderSlot(_ctx.$slots, "tokenSelect", {}, () => [
        _createVNode(TokenSelectInput, {
          modelValue: _address.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_address.value = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (emit('update:address', $event)))
          ],
          weight: __props.weight,
          fixed: __props.fixedToken,
          options: __props.options,
          class: "mr-2",
          excludedTokens: __props.excludedTokens
        }, null, 8, ["modelValue", "weight", "fixed", "options", "excludedTokens"])
      ])
    ]),
    footer: _withCtx(() => [
      (_unref(isWalletReady) || (_unref(hasAmount) && _unref(hasToken)))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_unref(isWalletReady))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "cursor-pointer flex items-center",
                    onClick: setMax
                  }, [
                    _createTextVNode(_toDisplayString(__props.balanceLabel ? __props.balanceLabel : _ctx.$t('balance')) + ": ", 1),
                    (__props.balanceLoading)
                      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                          key: 0,
                          class: "w-12 h-4 mx-2"
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(fNum)(_unref(tokenBalance), 'token')), 1)),
                    (_unref(hasBalance) && !__props.noMax && !__props.disableMax)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          (!_unref(isMaxed))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('max')), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('maxed')), 1))
                        ], 64))
                      : _createCommentVNode("", true)
                  ])),
              _createElementVNode("div", null, [
                (_unref(hasAmount) && _unref(hasToken))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_unref(fNum)(_unref(tokenValue), _unref(currency))) + " ", 1),
                      (__props.priceImpact)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_unref(priceImpactClass))
                          }, " (" + _toDisplayString(_unref(priceImpactSign) + _unref(fNum)(__props.priceImpact, 'percent')) + ") ", 3))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (__props.hint)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "text-blue-500 lowercase cursor-pointer",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('update:amount', __props.hintAmount)))
                      }, _toDisplayString(__props.hint), 1))
                    : _createCommentVNode("", true)
              ])
            ]),
            (_unref(hasBalance) && !__props.noMax)
              ? (_openBlock(), _createBlock(_component_BalProgressBar, {
                  key: 0,
                  width: _unref(maxPercentage),
                  "buffer-width": _unref(bufferPercentage),
                  color: _unref(barColor),
                  class: "mt-2"
                }, null, 8, ["width", "buffer-width", "color"]))
              : _createCommentVNode("", true),
            (_unref(shouldShowTxBufferMessage))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(t)('minTransactionBuffer', [
              _unref(nativeAsset).minTransactionBuffer,
              _unref(nativeAsset).symbol
            ])), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["modelValue", "placeholder", "label", "decimalLimit", "rules"]))
}
}

})