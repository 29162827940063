import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3f11280f")
const _hoisted_1 = { class: "data-table" }
const _hoisted_2 = { class: "data-table-row total-row" }
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "data-table-number-col" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_6 = {
  key: 0,
  class: "text-gray-400 dark:text-gray-600"
}
const _hoisted_7 = { class: "p-2" }
const _hoisted_8 = { class: "data-table-number-col" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "text-sm font-semibold"
}
const _hoisted_12 = {
  key: 0,
  class: "text-gray-400 dark:text-gray-600"
}
_popScopeId()

import { computed, toRefs } from 'vue';
import useNumbers from '@/composables/useNumbers';
import { InvestMathResponse } from '../composables/useInvestMath';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  math: InvestMathResponse;
};

/**
 * Props
 */

export default _defineComponent({
  props: {
    math: { type: null, required: true }
  } as unknown as undefined,
  emits: ["maximize", "optimize"] as unknown as undefined,
  setup(__props: {
  math: InvestMathResponse;
}, { emit }: { emit: ({
  (e: 'maximize'): void;
  (e: 'optimize'): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { isWalletReady } = useWeb3();

const {
  fiatTotal,
  hasNoBalances,
  hasAllTokens,
  priceImpact,
  highPriceImpact,
  maximized,
  optimized,
  batchSwapLoading,
  supportsPropotionalOptimization
} = toRefs(props.math);

/**
 * COMPUTED
 */
const priceImpactClasses = computed(() => ({
  'dark:bg-gray-800': !highPriceImpact.value,
  'bg-red-500 dark:bg-red-500 text-white divide-red-400': highPriceImpact.value
}));

const optimizeBtnClasses = computed(() => ({
  'text-gradient': !highPriceImpact.value,
  'text-red-500 px-2 py-1 bg-white rounded-lg': highPriceImpact.value
}));

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('total')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString(_unref(fNum)(_unref(fiatTotal), 'usd')) + " ", 1),
        (_unref(isWalletReady) && !_unref(hasNoBalances))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_unref(maximized))
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('maxed')), 1))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "text-blue-500 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('maximize')))
                  }, _toDisplayString(_ctx.$t('max')), 1))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['data-table-row price-impact-row', _unref(priceImpactClasses)])
    }, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('priceImpact')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (!_unref(batchSwapLoading))
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(fNum)(_unref(priceImpact), 'percent')), 1))
            : (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                key: 1,
                class: "w-10"
              })),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('customAmountsTip')
          }, {
            activator: _withCtx(() => [
              (_unref(highPriceImpact))
                ? (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 0,
                    name: "alert-triangle",
                    size: "xs",
                    class: "-mb-px ml-1"
                  }))
                : (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 1,
                    name: "info",
                    size: "xs",
                    class: "text-gray-400 -mb-px ml-1"
                  }))
            ]),
            _: 1
          }, 8, ["text"])
        ]),
        (
            _unref(isWalletReady) && _unref(hasAllTokens) && _unref(supportsPropotionalOptimization)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_unref(optimized))
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('optimized')), 1))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(['cursor-pointer', _unref(optimizeBtnClasses)]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('optimize')))
                  }, _toDisplayString(_ctx.$t('optimize')), 3))
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}
}

})