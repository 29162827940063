
import { defineComponent, toRefs, computed } from 'vue';
import useNumbers from '@/composables/useNumbers';
import useTokenApproval from '@/composables/trade/useTokenApproval';
import useRelayerApproval, {
  Relayer
} from '@/composables/trade/useRelayerApproval';
import useTokens from '@/composables/useTokens';

import { NATIVE_ASSET_ADDRESS } from '@/constants/tokens';
import { getWrapAction, WrapType } from '@/lib/utils/balancer/wrapper';
import { isStETH } from '@/lib/utils/balancer/lido';

export default defineComponent({
  emits: ['trade', 'close'],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isV1Swap: {
      type: Boolean,
      required: true
    },
    addressIn: {
      type: String,
      required: true
    },
    amountIn: {
      type: String,
      required: true
    },
    addressOut: {
      type: String,
      required: true
    },
    amountOut: {
      type: String,
      required: true
    },
    trading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const { fNum, toFiat } = useNumbers();

    const { addressIn, amountIn, addressOut, isV1Swap } = toRefs(props);

    const { tokens, approvalRequired } = useTokens();

    const wrapType = computed(() =>
      getWrapAction(addressIn.value, addressOut.value)
    );
    const isWrap = computed(() => wrapType.value === WrapType.Wrap);
    const isUnwrap = computed(() => wrapType.value === WrapType.Unwrap);

    const isStETHTrade = computed(
      () =>
        isStETH(addressIn.value, addressOut.value) &&
        wrapType.value === WrapType.NonWrap
    );

    const tokenApproval = useTokenApproval(addressIn, amountIn, tokens);

    const lidoRelayerApproval = useRelayerApproval(Relayer.LIDO, isStETHTrade);

    const valueIn = computed(() => toFiat(amountIn.value, addressIn.value));

    const symbolIn = computed(() => {
      const token = tokens.value[addressIn.value];
      if (!token) {
        return '';
      }
      return token.symbol;
    });

    const symbolOut = computed(() => {
      const token = tokens.value[addressOut.value];
      if (!token) {
        return '';
      }
      return token.symbol;
    });

    const isEthTrade = computed(() => addressIn.value === NATIVE_ASSET_ADDRESS);

    const requiresTokenApproval = computed(() =>
      isUnwrap.value || isEthTrade.value ? false : true
    );

    const isLidoRelayerApproved = computed(
      () => lidoRelayerApproval.isUnlocked.value
    );
    const requiresLidoRelayerApproval = computed(
      () =>
        isStETHTrade.value &&
        (!isLidoRelayerApproved.value || lidoRelayerApproval.approved.value)
    );

    const requiresApproval = computed(
      () => requiresTokenApproval.value || requiresLidoRelayerApproval.value
    );

    const isTokenApproved = computed(() => {
      if (tokenApproval.approved.value) {
        return true;
      }

      const { isUnlockedV1, isUnlockedV2 } = tokenApproval.allowanceState.value;
      if (isWrap.value && !isEthTrade.value) {
        // If we're wrapping a token other than native ETH
        // we need to approve the underlying on the wrapper
        return !approvalRequired(
          addressIn.value,
          amountIn.value,
          addressOut.value
        );
      }
      return isV1Swap.value ? isUnlockedV1 : isUnlockedV2;
    });

    async function approveLidoRelayer(): Promise<void> {
      await lidoRelayerApproval.approve();
    }

    async function approveToken(): Promise<void> {
      if (isWrap.value && !isEthTrade.value) {
        // If we're wrapping a token other than native ETH
        // we need to approve the underlying on the wrapper
        await tokenApproval.approveSpender(addressOut.value);
      } else if (isV1Swap.value) {
        await tokenApproval.approveV1();
      } else {
        await tokenApproval.approveV2();
      }
    }

    const approvingToken = computed(() => tokenApproval.approving.value);

    const approvingLidoRelayer = computed(
      () =>
        lidoRelayerApproval.init.value || lidoRelayerApproval.approving.value
    );

    const totalRequiredTransactions = computed(() => {
      let txCount = 1; // trade

      if (requiresTokenApproval.value) {
        txCount++;
      }
      if (requiresLidoRelayerApproval.value) {
        txCount++;
      }
      return txCount;
    });

    function trade() {
      emit('trade');
    }

    function onClose() {
      emit('close');
    }

    return {
      // methods
      fNum,
      onClose,
      approveLidoRelayer,
      approveToken,
      trade,
      // computed
      requiresApproval,
      requiresTokenApproval,
      requiresLidoRelayerApproval,
      isTokenApproved,
      isLidoRelayerApproved,
      valueIn,
      symbolIn,
      symbolOut,
      approvingLidoRelayer,
      approvingToken,
      lidoRelayerApproval,
      totalRequiredTransactions
    };
  }
});
