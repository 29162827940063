
import { defineComponent, reactive, toRefs, computed, watch } from 'vue';
import * as PoolPageComponents from '@/components/contextual/pages/pool';
import GauntletIcon from '@/components/images/icons/GauntletIcon.vue';
import LiquidityAPRTooltip from '@/components/tooltips/LiquidityAPRTooltip.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useNumbers from '@/composables/useNumbers';
import { usePool } from '@/composables/usePool';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import useFarmsQuery from '@/composables/queries/useFarmsQuery';
import useSymmetricQueries from '@/composables/queries/useSymmetricQueries';
import usePoolSnapshotsQuery from '@/composables/queries/usePoolSnapshotsQuery';
import { POOLS } from '@/constants/pools';
import { EXTERNAL_LINKS } from '@/constants/links';
import useWeb3 from '@/services/web3/useWeb3';
import useTokens from '@/composables/useTokens';
import useApp from '@/composables/useApp';
import useAlerts, { AlertPriority, AlertType } from '@/composables/useAlerts';

interface PoolPageData {
  id: string;
}

export default defineComponent({
  components: {
    ...PoolPageComponents,
    GauntletIcon,
    LiquidityAPRTooltip
  },

  setup() {
    /**
     * COMPOSABLES
     */
    const { appLoading } = useApp();
    const { t } = useI18n();
    const route = useRoute();
    const { fNum } = useNumbers();
    const { isWalletReady } = useWeb3();
    const { prices } = useTokens();
    const { blockNumber, isKovan, isMainnet, isPolygon, isCelo } = useWeb3();
    const { addAlert, removeAlert } = useAlerts();
    const { balancerTokenListTokens } = useTokens();

    /**
     * QUERIES
     */
    const poolQuery = usePoolQuery(route.params.id as string);
    const farmQuery = useFarmsQuery(route.params.id.toString().substr(0, 42));
    // const v2Prices = useSymmetricQueries(['0x8427bd503dd3169ccc9aff7326c15258bc305478']);
    // console.log('V2 prices', v2Prices)

    const poolSnapshotsQuery = usePoolSnapshotsQuery(
      route.params.id as string,
      30
    );

    /**
     * STATE
     */
    const data = reactive<PoolPageData>({
      id: route.params.id as string
    });

    /**
     * COMPUTED
     */
    const pool = computed(() => poolQuery.data.value);
    const farm = computed(() => farmQuery.data.value?.pages[0]);
    const {
      isStableLikePool,
      isLiquidityBootstrappingPool,
      isStablePhantomPool
    } = usePool(poolQuery.data);

    const noInitLiquidity = computed(
      () =>
        !loadingPool.value &&
        pool.value &&
        Number(pool.value.onchain.totalSupply) === 0
    );

    const farmAvailable = computed(() => farm?.value?.pools.length);
    const communityManagedFees = computed(
      () =>
        pool.value?.owner ==
        (isCelo.value ? POOLS.DelegateOwner : POOLS.gnosisDelegateOwner)
    );
    const feesManagedByGauntlet = computed(
      () =>
        communityManagedFees.value &&
        POOLS.DynamicFees.Gauntlet.includes(data.id)
    );
    const feesFixed = computed(() => pool.value?.owner == POOLS.ZeroAddress);
    const swapFeeToolTip = computed(() => {
      if (feesManagedByGauntlet.value) {
        return t('feesManagedByGauntlet');
      } else if (communityManagedFees.value) {
        return t('delegateFeesTooltip');
      } else if (feesFixed.value) {
        return t('fixedFeesTooltip');
      } else {
        return t('ownerFeesTooltip');
      }
    });

    const poolQueryLoading = computed(
      () =>
        poolQuery.isLoading.value ||
        poolQuery.isIdle.value ||
        poolQuery.error.value
    );
    const farmQueryLoading = computed(
      () =>
        farmQuery.isLoading.value ||
        farmQuery.isIdle.value ||
        farmQuery.error.value
    );

    const loadingPool = computed(() => poolQueryLoading.value || !pool.value);
    const loadingFarm = computed(() => farmQueryLoading.value || !farm.value);

    const snapshots = computed(() => poolSnapshotsQuery.data.value?.snapshots);
    const historicalPrices = computed(
      () => poolSnapshotsQuery.data.value?.prices
    );
    const isLoadingSnapshots = computed(
      () =>
        poolSnapshotsQuery.isLoading.value || poolSnapshotsQuery.isIdle.value
    );

    const titleTokens = computed(() => {
      if (!pool.value) return [];

      return Object.entries(pool.value.onchain.tokens).sort(
        ([, a]: any[], [, b]: any[]) => b.weight - a.weight
      );
    });

    const poolTypeLabel = computed(() => {
      if (!pool.value) return '';
      const key: string | undefined = Object.keys(POOLS.Factories).find(
        (key: string) => key.toLowerCase() === pool.value?.factory.toLowerCase()
      );
      if (!key) return '';
      const value = POOLS.Factories[key];
      return value ? t(value) : t('unknownPoolType');
    });

    const poolFeeLabel = computed(() => {
      if (!pool.value) return '';
      const feeLabel = `${fNum(
        pool.value.onchain.swapFee,
        'percent_variable'
      )}`;

      if (feesFixed.value) {
        return t('fixedSwapFeeLabel', [feeLabel]);
      } else if (communityManagedFees.value) {
        return feesManagedByGauntlet.value
          ? t('dynamicSwapFeeLabel', [feeLabel])
          : t('communitySwapFeeLabel', [feeLabel]);
      }

      // Must be owner-controlled
      return t('dynamicSwapFeeLabel', [feeLabel]);
    });

    const missingPrices = computed(() => {
      if (pool.value) {
        const tokensWithPrice = Object.keys(prices.value);

        const tokens =
          isStablePhantomPool.value && pool.value.mainTokens
            ? pool.value.mainTokens
            : pool.value.tokenAddresses;

        return !tokens.every(token => tokensWithPrice.includes(token));
      }
      return false;
    });

    const isCopperNetworkSupported = computed(
      () => isMainnet.value || isPolygon.value || isKovan.value
    );

    // Temporary solution to hide Copper card on Fei pool page.
    // Longer terms solution is needed distinguish LBP platforms
    // and display custom widgets linking to their pages.
    const isCopperPool = computed((): boolean => {
      const feiPoolId =
        '0xede4efcc5492cf41ed3f0109d60bc0543cfad23a0002000000000000000000bb';
      return (
        !!pool.value &&
        isLiquidityBootstrappingPool.value &&
        pool.value.id !== feiPoolId &&
        isCopperNetworkSupported.value
      );
    });

    const copperNetworkPrefix = computed(() => {
      if (isPolygon.value) {
        return 'polygon.';
      }
      if (isKovan.value) {
        return 'kovan.';
      }
      return '';
    });

    const hasCustomToken = computed(() => {
      const knownTokens = Object.keys(balancerTokenListTokens.value);
      return (
        !!pool.value &&
        !isLiquidityBootstrappingPool.value &&
        !isStablePhantomPool.value &&
        pool.value.tokenAddresses.some(
          address => !knownTokens.includes(address)
        )
      );
    });

    /**
     * METHODS
     */
    function onNewTx(): void {
      poolQuery.refetch.value();
    }

    /**
     * WATCHERS
     */
    watch(blockNumber, () => {
      poolQuery.refetch.value();
    });

    watch(poolQuery.error, () => {
      if (poolQuery.error.value) {
        addAlert({
          id: 'pool-fetch-error',
          label: t('alerts.pool-fetch-error'),
          type: AlertType.ERROR,
          persistent: true,
          action: poolQuery.refetch.value,
          actionLabel: t('alerts.retry-label'),
          priority: AlertPriority.MEDIUM
        });
      } else {
        removeAlert('pool-fetch-error');
      }
    });

    return {
      // data
      ...toRefs(data),
      EXTERNAL_LINKS,
      // computed
      appLoading,
      pool,
      farm,
      noInitLiquidity,
      farmAvailable,
      poolTypeLabel,
      poolFeeLabel,
      historicalPrices,
      snapshots,
      isLoadingSnapshots,
      loadingPool,
      titleTokens,
      isWalletReady,
      missingPrices,
      feesManagedByGauntlet,
      swapFeeToolTip,
      isStableLikePool,
      isLiquidityBootstrappingPool,
      isCopperPool,
      isStablePhantomPool,
      copperNetworkPrefix,
      hasCustomToken,
      // methods
      fNum,
      onNewTx
    };
  }
});
