import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-e1be5ff0")
const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "px-4 py-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-gray-500 text-sm" }
const _hoisted_7 = { class: "flex flex-col flex-grow text-right" }
const _hoisted_8 = { class: "text-gray-500 text-sm" }
_popScopeId()

import { toRef, computed, ref } from 'vue';
import { FullPool } from '@/services/balancer/subgraph/types';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import useWeb3 from '@/services/web3/useWeb3';
import { usePool } from '@/composables/usePool';
import PoolCalculator from '@/services/pool/calculator/calculator.sevice';
import { bnum } from '@/lib/utils';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { tokens, balances, balanceFor, getTokens } = useTokens();
const { fNum, toFiat } = useNumbers();
const { currency } = useUserSettings();
const { isWalletReady } = useWeb3();
const { isStableLikePool, isStablePhantomPool } = usePool(toRef(props, 'pool'));

/**
 * SERVICES
 */
const poolCalculator = new PoolCalculator(
  toRef(props, 'pool'),
  tokens,
  balances,
  'exit',
  ref(false)
);

/**
 * COMPUTED
 */
const bptBalance = computed((): string => balanceFor(props.pool.address));

const poolTokens = computed(() =>
  Object.values(getTokens(props.pool.tokenAddresses))
);

const propTokenAmounts = computed((): string[] => {
  const { receive } = poolCalculator.propAmountsGiven(
    bptBalance.value,
    0,
    'send'
  );

  if (isStablePhantomPool.value) {
    // Return linear pool's main token balance using the price rate.
    // mainTokenBalance = linearPoolBPT * priceRate
    return props.pool.tokenAddresses.map((address, i) => {
      if (!props.pool.onchain.linearPools) return '0';

      const priceRate = props.pool.onchain.linearPools[address].priceRate;

      return bnum(receive[i])
        .times(priceRate)
        .toString();
    });
  }

  return receive;
});

const tokenAddresses = computed((): string[] => {
  if (isStablePhantomPool.value) {
    // We're using mainToken balances for StablePhantom pools
    // so return mainTokens here so that fiat values are correct.
    return props.pool.mainTokens || [];
  }
  return props.pool.tokenAddresses;
});

const fiatTotal = computed(() => {
  const fiatValue = tokenAddresses.value
    .map((address, i) => toFiat(propTokenAmounts.value[i], address))
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    );
  return fNum(fiatValue, currency.value);
});

/**
 * METHODS
 */
function weightLabelFor(address: string): string {
  return fNum(props.pool.onchain.tokens[address].weight, 'percent_lg');
}

function fiatLabelFor(index: number, address: string): string {
  const fiatValue = toFiat(propTokenAmounts.value[index], address);
  return fNum(fiatValue, currency.value);
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, { noPad: "" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('poolTransfer.myPoolBalancesCard.title')), 1),
        _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fiatTotal) : '-'), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses), (address, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: address,
            class: "asset-row"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BalAsset, {
                address: _unref(poolTokens)[index].address,
                size: 36,
                class: "mr-4"
              }, null, 8, ["address"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, [
                  (!_unref(isStableLikePool))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(weightLabelFor(address)), 1))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_unref(poolTokens)[index].symbol), 1)
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(poolTokens)[index].name), 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_7, [
              _createTextVNode(_toDisplayString(_unref(isWalletReady) ? _unref(fNum)(_unref(propTokenAmounts)[index], 'token') : '-') + " ", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(isWalletReady) ? fiatLabelFor(index, address) : '-'), 1)
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})