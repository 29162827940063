import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b2a8fa78")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "flex flex-col py-2" }
const _hoisted_4 = { class: "summary-table-row" }
const _hoisted_5 = { class: "summary-table-label" }
const _hoisted_6 = { class: "summary-table-number" }
const _hoisted_7 = { class: "summary-table-row" }
const _hoisted_8 = { class: "summary-table-label" }
const _hoisted_9 = { class: "summary-table-number" }
_popScopeId()

import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { FullPool } from '@/services/balancer/subgraph/types';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  fiatTotal: string;
  priceImpact: number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    fiatTotal: { type: String, required: true },
    priceImpact: { type: Number, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  fiatTotal: string;
  priceImpact: number;
}) {



/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { currency } = useUserSettings();

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('summary')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('total')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_unref(fNum)(__props.fiatTotal, _unref(currency))) + " ", 1),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('tooltips.withdraw.total', [_unref(currency).toUpperCase()]),
            "icon-size": "sm",
            class: "ml-2"
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('priceImpact')), 1),
        _createElementVNode("div", _hoisted_9, [
          _createTextVNode(_toDisplayString(_unref(fNum)(__props.priceImpact, 'percent')) + " ", 1),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('tooltips.withdraw.priceImpact'),
            "icon-size": "sm",
            width: "72",
            class: "ml-2"
          }, null, 8, ["text"])
        ])
      ])
    ])
  ]))
}
}

})