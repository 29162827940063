import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-116b3b96")
const _hoisted_1 = { class: "proportional-input" }
const _hoisted_2 = { class: "proportional-input-container" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex-grow text-right text-xl font-numeric" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex mt-2 text-sm text-gray-500" }
const _hoisted_7 = { class: "flex-grow text-right" }
const _hoisted_8 = { class: "token-amounts" }
const _hoisted_9 = { class: "flex items-center justify-between" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex flex-col leading-none" }
const _hoisted_12 = { class: "text-lg font-medium" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "flex flex-col flex-grow items-end text-right pl-2 font-numeric" }
const _hoisted_15 = { class: "break-words text-xl" }
const _hoisted_16 = { class: "text-sm text-gray-400" }
_popScopeId()

import { toRefs, computed, toRef, onBeforeMount, watch } from 'vue';
import { bnum } from '@/lib/utils';
import BigNumber from 'bignumber.js';
// Types
import { FullPool } from '@/services/balancer/subgraph/types';
import { TokenInfoMap } from '@/types/TokenList';
// Composables
import useNumbers from '@/composables/useNumbers';
import { isStablePhantom, usePool } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import { WithdrawMathResponse } from '../composables/useWithdrawMath';
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import useWeb3 from '@/services/web3/useWeb3';
import useWithdrawalState from '../composables/useWithdrawalState';
// Components
import WithdrawalTokenSelect from './WithdrawalTokenSelect.vue';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  tokenAddresses: string[];
  math: WithdrawMathResponse;
};

/**
 * Props
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    tokenAddresses: { type: Array, required: true },
    math: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  tokenAddresses: string[];
  math: WithdrawMathResponse;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const {
  propBptIn,
  bptBalance,
  hasBpt,
  fiatTotalLabel,
  fiatAmounts,
  proportionalAmounts,
  shouldFetchBatchSwap,
  loadingAmountsOut
} = toRefs(props.math);

const { slider } = useWithdrawalState(toRef(props, 'pool'));

const { isWalletReady } = useWeb3();
const { missingPrices } = usePoolTransfers();
const { getTokens } = useTokens();
const { isStableLikePool } = usePool(toRef(props, 'pool'));
const { fNum } = useNumbers();

/**
 * COMPUTED
 */
const tokens = computed(
  (): TokenInfoMap => {
    if (isStablePhantom(props.pool.poolType)) {
      return getTokens(props.pool.mainTokens || []);
    }
    return getTokens(props.pool.tokenAddresses);
  }
);

const percentageLabel = computed(() => {
  try {
    if (!hasBpt.value) return '100';

    return bnum(propBptIn.value)
      .div(bptBalance.value)
      .times(100)
      .integerValue(BigNumber.ROUND_CEIL)
      .toString();
  } catch (error) {
    console.error(error);
    return '0';
  }
});

const seedTokens = computed((): number[] =>
  Object.values(props.pool.onchain.tokens).map(token => token.weight)
);

/**
 * METHODS
 */
function handleSliderChange(newVal: number): void {
  const fractionBasisPoints = (newVal / slider.value.max) * 10000;
  propBptIn.value = bnum(bptBalance.value)
    .times(fractionBasisPoints)
    .div(10000)
    .toFixed(props.pool.onchain.decimals);
}

async function handleSliderEnd(): Promise<void> {
  if (shouldFetchBatchSwap.value) {
    await props.math.getSwap();
  }
}

/**
 * WATCHERS
 */
watch(isWalletReady, () => {
  propBptIn.value = bptBalance.value;
});

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  propBptIn.value = bptBalance.value;
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalRangeInput = _resolveComponent("BalRangeInput")!
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(WithdrawalTokenSelect, { pool: __props.pool }, null, 8, ["pool"]),
          _createElementVNode("div", _hoisted_4, [
            (_unref(loadingAmountsOut))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "w-20 h-8 float-right"
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(missingPrices) ? '-' : _unref(fiatTotalLabel)), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('proportionalWithdrawal')), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(percentageLabel)) + "%", 1)
        ]),
        _createVNode(_component_BalRangeInput, {
          modelValue: _unref(slider).val,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_unref(slider).val = $event)),
            handleSliderChange
          ],
          max: _unref(slider).max,
          interval: _unref(slider).interval,
          min: _unref(slider).min,
          tooltip: "none",
          disabled: !_unref(hasBpt),
          onDragEnd: handleSliderEnd
        }, null, 8, ["modelValue", "max", "interval", "min", "disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokens), (token, address, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: address,
          class: "p-4 last:mb-0"
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_BalAsset, {
                address: address,
                class: "mr-2"
              }, null, 8, ["address"]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, [
                  _createTextVNode(_toDisplayString(token.symbol) + " ", 1),
                  (!_unref(isStableLikePool))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(fNum)(_unref(seedTokens)[i], 'percent_lg')), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_unref(loadingAmountsOut))
                ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                    key: 0,
                    class: "w-20 h-12"
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(fNum)(_unref(proportionalAmounts)[i], 'token')), 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(fNum)(_unref(fiatAmounts)[i], 'usd')), 1)
                  ], 64))
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})