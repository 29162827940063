import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "text-sm divide-y dark:divide-gray-900" }
const _hoisted_3 = { class: "px-3 pt-3 pb-1 bg-gray-50 dark:bg-gray-800 rounded-t" }
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "text-lg" }
const _hoisted_6 = { class: "p-3" }
const _hoisted_7 = { class: "whitespace-nowrap flex items-center mb-1" }
const _hoisted_8 = { class: "ml-1 text-gray-500 text-xs" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { class: "ml-1 text-gray-500 text-xs" }
const _hoisted_11 = { class: "text-gray-500 text-xs ml-1" }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { class: "ml-1 text-gray-500 text-xs flex items-center" }
const _hoisted_14 = { class: "text-gray-500 text-xs ml-1" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { isStablePhantom, isWstETH } from '@/composables/usePool';
import { APR_THRESHOLD } from '@/constants/poolAPR';

import { DecoratedPool } from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';

/**
 * TYPES
 */
type Props = {
  pool: DecoratedPool;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: DecoratedPool;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { getTokens } = useTokens();
const { t } = useI18n();

/**
 * COMPUTED
 */
const lmBreakdown = computed(
  () => props.pool.dynamic.apr.liquidityMiningBreakdown
);

const validAPR = computed(
  () => Number(props.pool.dynamic.apr.total) * 100 <= APR_THRESHOLD
);

const lmTokens = computed(() => getTokens(Object.keys(lmBreakdown.value)));

const lmMultiRewardPool = computed(
  () => Object.keys(lmTokens.value).length > 1
);

const hasThirdPartyAPR = computed(() =>
  bnum(props.pool.dynamic.apr.thirdParty).gt(0)
);

const thirdPartyBreakdown = computed(
  () => props.pool.dynamic.apr.thirdPartyBreakdown
);

const thirdPartyTokens = computed(() =>
  getTokens(Object.keys(thirdPartyBreakdown.value))
);

const thirdPartyMultiRewardPool = computed(
  () => Object.keys(thirdPartyTokens.value).length > 1
);

const thirdPartyAPRLabel = computed(() => {
  if (isWstETH(props.pool)) return t('thirdPartyRewards.apr.steth');
  if (isStablePhantom(props.pool.poolType))
    return t('thirdPartyRewards.apr.aaveBoosted');

  return '';
});

return (_ctx: any,_cache: any) => {
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_unref(validAPR))
    ? (_openBlock(), _createBlock(_component_BalTooltip, {
        key: 0,
        width: "auto",
        noPad: ""
      }, {
        activator: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (__props.pool.hasLiquidityMiningRewards || _unref(hasThirdPartyAPR))
              ? (_openBlock(), _createBlock(_component_StarsIcon, _mergeProps({
                  key: 0,
                  class: "h-5 text-yellow-300"
                }, _ctx.$attrs), null, 16))
              : (_openBlock(), _createBlock(_component_BalIcon, _mergeProps({
                  key: 1,
                  name: "info",
                  size: "sm",
                  class: "text-gray-400 dark:text-gray-500"
                }, _ctx.$attrs), null, 16))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('totalAPR')), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fNum)(__props.pool.dynamic.apr.total, 'percent')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(_unref(fNum)(__props.pool.dynamic.apr.pool, 'percent')) + " ", 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('swapFeeAPR')), 1)
              ]),
              (_unref(hasThirdPartyAPR))
                ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                    key: 0,
                    items: Object.entries(_unref(thirdPartyBreakdown)),
                    hideItems: !_unref(thirdPartyMultiRewardPool)
                  }, _createSlots({
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createTextVNode(_toDisplayString(_unref(fNum)(__props.pool.dynamic.apr.thirdParty, 'percent')) + " ", 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(thirdPartyAPRLabel)), 1)
                      ])
                    ]),
                    _: 2
                  }, [
                    (_unref(thirdPartyMultiRewardPool))
                      ? {
                          name: "item",
                          fn: _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_unref(fNum)(item[1], 'percent')) + " ", 1),
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(thirdPartyTokens)[item[0]].symbol) + " " + _toDisplayString(_ctx.$t('apr')), 1)
                          ])
                        }
                      : undefined
                  ]), 1032, ["items", "hideItems"]))
                : _createCommentVNode("", true),
              (__props.pool.hasLiquidityMiningRewards)
                ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                    key: 1,
                    items: Object.entries(_unref(lmBreakdown)),
                    hideItems: !_unref(lmMultiRewardPool)
                  }, _createSlots({
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createTextVNode(_toDisplayString(_unref(fNum)(__props.pool.dynamic.apr.liquidityMining, 'percent')) + " ", 1),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('liquidityMiningAPR')), 1)
                      ])
                    ]),
                    _: 2
                  }, [
                    (_unref(lmMultiRewardPool))
                      ? {
                          name: "item",
                          fn: _withCtx(({ item }) => [
                            _createTextVNode(_toDisplayString(_unref(fNum)(item[1], 'percent')) + " ", 1),
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(lmTokens)[item[0]].symbol) + " " + _toDisplayString(_ctx.$t('apr')), 1)
                          ])
                        }
                      : undefined
                  ]), 1032, ["items", "hideItems"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})