import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-00fd95e4")
const _hoisted_1 = { class: "token-amount-table" }
const _hoisted_2 = { class: "token-amount-table-content" }
const _hoisted_3 = { class: "flex flex-col ml-3" }
const _hoisted_4 = { class: "font-medium text-lg" }
const _hoisted_5 = { class: "font-numeric" }
const _hoisted_6 = { class: "text-sm text-gray-500 font-numeric" }
_popScopeId()

import useNumbers from '@/composables/useNumbers';
import useUserSettings from '@/composables/useUserSettings';
import { bnum } from '@/lib/utils';
import { TokenInfoMap } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
};

type AmountMap = {
  [address: string]: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    amountMap: { type: Object, required: true },
    fiatAmountMap: { type: Object, required: true },
    tokenMap: { type: null, required: true },
    fiatTotal: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  amountMap: AmountMap;
  fiatAmountMap: AmountMap;
  tokenMap: TokenInfoMap;
  fiatTotal: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum } = useNumbers();
const { currency } = useUserSettings();

/**
 * METHODS
 */
// The investment amount's relative percentage of the total fiat investment value.
// This has nothing to do with the pool weights.
function amountShare(address: string): string {
  return bnum(props.fiatAmountMap[address])
    .div(props.fiatTotal)
    .toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.amountMap, (amount, address) => {
      return (_openBlock(), _createElementBlock("div", {
        key: address,
        class: "relative"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BalAsset, {
            address: address,
            size: 36
          }, null, 8, ["address"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(fNum)(amount, 'token')), 1),
              _createTextVNode(" " + _toDisplayString(__props.tokenMap[address].symbol), 1)
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(fNum)(__props.fiatAmountMap[address], _unref(currency))) + " (" + _toDisplayString(_unref(fNum)(amountShare(address), 'percent')) + ") ", 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})